.container-modal-filter {

  .modal-header-filter {
    background: var(--bs-white);
    display: initial !important;

    .container-title-header {
      margin: 1rem 0;

      h1 {
        font-size: 1.3rem;
      }
    }
  }

  >div {
    padding-top: 0rem !important;
  }
}

.container-body-modal {
  gap: 1.5rem;

  label {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.flatpickr-calendar .flatpickr-day.inRange,
[dir] .flatpickr-calendar .flatpickr-day.inRange:hover {
  background-color: #F8F8F8 !important;
  border-color: #F8F8F8 !important;
}

.flatpickr-calendar .flatpickr-day.inRange,
[dir] .flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: -5px 0 0 #F8F8F8, 5px 0 0 #F8F8F8 !important;
}

.fileText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}