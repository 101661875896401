.select-container{
  list-style: none;
  .select-button{
    border: 1px solid #d8d6de;
    padding: 0.571rem 1rem;
    border-radius: 0.357rem;
    background: var(--bs-white);
  }
  .select-button:focus{
    border: 1px solid #00aa44;
  }
  
  .scroll{
    height: min-content !important;
    max-height: 60% !important;
    padding-left: 0;
    z-index: 2;
    left: 5%;
    width: 90%;
    position: absolute !important;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  }
  .select-container-options{
    width: 100%;
    li{
      padding: 0.6rem 0.6rem 0.6rem 0.6rem;
      font-weight: 400;
      width: 100%;
      cursor: pointer;
    }
    li:hover{
      background:#f8f8f8;
      color: #00aa44;
      border-radius: 0.4rem;
    }
  }

}


.width-scroll-30{
 width: 30% !important;
}
.width-scroll-40{
 width: 40% !important;
}
.width-scroll-45{
 width: 45% !important;
}
.width-scroll-48{
 width: 48% !important;
}
.width-scroll-50{
 width: 50% !important;
}

.width-scroll-70{
  width: 70% !important;
}
.width-scroll-60{
  width: 60% !important;
}

.width-scroll-80{
  width: 80% !important;
}

.position{
  left: 53% !important;
}
.position-51{
  left: 51% !important;
}
.position-58{
  left: 68% !important;
}
.position-26{
  left: 26% !important;
}
.position-right-1{
  left: 1% !important;
}
.position-right-2{
  left: 2% !important;
}
.position-right-3{
  left: 3% !important;
}
.position-right-5{
  left: 5% !important;
}
.position-right-10{
  left: 10% !important;
}
.position-right-12{
  left: 12% !important;
}