.button-ticket-container {
  position: absolute;

  button {
    bottom: 31px;
    right: 20px;
    position: fixed;
    z-index: 1900;
    border: 0px;
  }
}